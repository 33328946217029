@import "@/styles/_variables.scss";










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.custom-chart {
  height: 350px;
}
.no-data {
  position: absolute;
  top: 45%;
  left: 40%;
}
.input-date-picker .field-input {
  height: 40px;
}
.export {
  padding: 0 20px;
}
